import { DispatchType } from 'root/types/businessTypes';
import type { WidgetDesignTabsArray } from '../types/widgets';
import {
  DISHES_WIDGET_COMPONENT_IDS,
  HEADER_WIDGET_COMPONENT_IDS,
  UNAVAILABLE_DISHES_COMPONENT_IDS,
  OLO_WIDGET_COMPONENT_IDS,
  FULFILLMENT_PICKER_WIDGET_COMPONENT_IDS,
  NAVIGATION_TOOLBAR_IDS,
  ERROR_MODAL_COMPONENT_IDS,
  CART_BUTTON_COMPONENT_IDS,
} from './blocksIds';

export const CART_BUTTON_DESIGN_PANEL_TABS: WidgetDesignTabsArray = [
  {
    label: 'design-panel.tabs.cart-button.button',
    roles: [CART_BUTTON_COMPONENT_IDS.button],
  },
  {
    label: 'design-panel.tabs.cart-button.divider',
    roles: [CART_BUTTON_COMPONENT_IDS.divider],
  },
  {
    label: 'design-panel.tabs.cart-button.background',
    roles: [CART_BUTTON_COMPONENT_IDS.viewCartBox],
  },
];

export const HEADER_TIME_LOCATION_DESIGN_PANEL_TABS: WidgetDesignTabsArray = [
  {
    label: 'design-panel.tabs.header-time-location-container.icons',
    roles: [HEADER_WIDGET_COMPONENT_IDS.timeIcon, HEADER_WIDGET_COMPONENT_IDS.locationIcon],
  },
  {
    label: 'design-panel.tabs.header-time-location-container.texts',
    roles: [HEADER_WIDGET_COMPONENT_IDS.timeInfo, HEADER_WIDGET_COMPONENT_IDS.addressInfo],
  },
  {
    label: 'design-panel.tabs.header-time-location-container.buttons',
    roles: [HEADER_WIDGET_COMPONENT_IDS.timeChange, HEADER_WIDGET_COMPONENT_IDS.addressChange],
  },
];

export const HEADER_FULFILLMENT_PICKER_DESIGN_PANEL_TABS: WidgetDesignTabsArray = [
  {
    label: 'design-panel.tabs.header-fulfillment-picker-default.background',
    roles: [
      FULFILLMENT_PICKER_WIDGET_COMPONENT_IDS[DispatchType.PICKUP].default,
      FULFILLMENT_PICKER_WIDGET_COMPONENT_IDS[DispatchType.DELIVERY].default,
    ],
  },
  {
    label: 'design-panel.tabs.header-fulfillment-picker-default.text',
    roles: [
      FULFILLMENT_PICKER_WIDGET_COMPONENT_IDS[DispatchType.PICKUP].defaultText,
      FULFILLMENT_PICKER_WIDGET_COMPONENT_IDS[DispatchType.DELIVERY].defaultText,
    ],
  },
  {
    label: 'design-panel.tabs.header-fulfillment-picker-selected.background',
    roles: [
      FULFILLMENT_PICKER_WIDGET_COMPONENT_IDS[DispatchType.PICKUP].selected,
      FULFILLMENT_PICKER_WIDGET_COMPONENT_IDS[DispatchType.DELIVERY].selected,
    ],
  },
  {
    label: 'design-panel.tabs.header-fulfillment-picker-selected.text',
    roles: [
      FULFILLMENT_PICKER_WIDGET_COMPONENT_IDS[DispatchType.PICKUP].selectedText,
      FULFILLMENT_PICKER_WIDGET_COMPONENT_IDS[DispatchType.DELIVERY].selectedText,
    ],
  },
];

export const HEADER_BADGES_DESIGN_PANEL_TABS: WidgetDesignTabsArray = [
  {
    label: 'design-panel.tabs.header-badges-container.text',
    roles: [
      HEADER_WIDGET_COMPONENT_IDS.minOrder,
      HEADER_WIDGET_COMPONENT_IDS.deliveryFee,
      HEADER_WIDGET_COMPONENT_IDS.acceptingOrder,
      HEADER_WIDGET_COMPONENT_IDS.freeDelivery,
    ],
  },
  {
    label: 'design-panel.tabs.header-badges-container.background',
    roles: [
      HEADER_WIDGET_COMPONENT_IDS.minOrderContainer,
      HEADER_WIDGET_COMPONENT_IDS.deliveryFeeContainer,
      HEADER_WIDGET_COMPONENT_IDS.acceptingOrderWrapper,
      HEADER_WIDGET_COMPONENT_IDS.freeDeliveryContainer,
    ],
  },
];

export const HEADER_TITLE_CONTAINER_DESIGN_PANEL_TABS: WidgetDesignTabsArray = [
  {
    label: 'design-panel.tabs.header-title',
    roles: [HEADER_WIDGET_COMPONENT_IDS.headerTitle],
  },
  {
    label: 'design-panel.tabs.header-description',
    roles: [HEADER_WIDGET_COMPONENT_IDS.headerDescription],
  },
];

export const HEADER_DESIGN_PANEL_TABS: WidgetDesignTabsArray = [
  {
    label: 'design-panel.tabs.header-image',
    roles: [HEADER_WIDGET_COMPONENT_IDS.headerImage],
  },
  {
    label: 'design-panel.tabs.header-title',
    roles: [HEADER_WIDGET_COMPONENT_IDS.headerTitle],
  },
  {
    label: 'design-panel.tabs.header-description',
    roles: [HEADER_WIDGET_COMPONENT_IDS.headerDescription],
  },
  {
    label: 'design-panel.tabs.header-badges.background',
    roles: [
      HEADER_WIDGET_COMPONENT_IDS.minOrderWrapper,
      HEADER_WIDGET_COMPONENT_IDS.deliveryFeeWrapper,
      HEADER_WIDGET_COMPONENT_IDS.acceptingOrderWrapper,
    ],
  },
  {
    label: 'design-panel.tabs.header-badges.text',
    roles: [
      HEADER_WIDGET_COMPONENT_IDS.minOrder,
      HEADER_WIDGET_COMPONENT_IDS.deliveryFee,
      HEADER_WIDGET_COMPONENT_IDS.acceptingOrder,
    ],
  },
  {
    label: 'design-panel.tabs.header-pickers',
    roles: [HEADER_WIDGET_COMPONENT_IDS.dispatchTypeContainer],
  },
  {
    label: 'design-panel.tabs.header-location-time.icons',
    roles: [HEADER_WIDGET_COMPONENT_IDS.timeIcon, HEADER_WIDGET_COMPONENT_IDS.locationIcon],
  },
  {
    label: 'design-panel.tabs.header-location-time.texts',
    roles: [HEADER_WIDGET_COMPONENT_IDS.timeInfo, HEADER_WIDGET_COMPONENT_IDS.addressInfo],
  },
  {
    label: 'design-panel.tabs.header-change-buttons',
    roles: [HEADER_WIDGET_COMPONENT_IDS.timeChange, HEADER_WIDGET_COMPONENT_IDS.addressChange],
  },
  {
    label: 'design-panel.tabs.header.background',
    roles: [HEADER_WIDGET_COMPONENT_IDS.headerContainer],
  },
];

export const OLO_WIDGET_DESIGN_PANEL_TABS: WidgetDesignTabsArray = [
  {
    label: 'design-panel.tabs.global.background',
    roles: [OLO_WIDGET_COMPONENT_IDS.globalBackground],
  },
  {
    label: 'design-panel.tabs.header.background',
    roles: [HEADER_WIDGET_COMPONENT_IDS.headerContainer],
  },
  {
    label: 'design-panel.tabs.items.background',
    roles: [
      DISHES_WIDGET_COMPONENT_IDS.dishContainerStripBox,
      UNAVAILABLE_DISHES_COMPONENT_IDS.itemContainerStripBox,
    ],
  },
];

export const DISHES_DESIGN_PANEL_TABS: WidgetDesignTabsArray = [
  {
    label: 'design-panel.tabs.items-title.text',
    roles: [DISHES_WIDGET_COMPONENT_IDS.itemTitle, UNAVAILABLE_DISHES_COMPONENT_IDS.itemTitle],
  },
  {
    label: 'design-panel.tabs.items-description.text',
    roles: [
      DISHES_WIDGET_COMPONENT_IDS.itemDescription,
      UNAVAILABLE_DISHES_COMPONENT_IDS.itemDescription,
    ],
  },
  {
    label: 'design-panel.tabs.items-price.text',
    roles: [DISHES_WIDGET_COMPONENT_IDS.itemPrice],
  },
  {
    label: 'design-panel.tabs.items-out-of-stock-price.text',
    roles: [UNAVAILABLE_DISHES_COMPONENT_IDS.itemPrice],
    tooltip: 'design-panel.tabs.items-out-of-stock-price.text.tooltip',
  },
  {
    label: 'design-panel.tabs.items-out-of-stock-price.image',
    roles: [UNAVAILABLE_DISHES_COMPONENT_IDS.itemImage],
    tooltip: 'design-panel.tabs.items-out-of-stock-price.image.tooltip',
  },
  {
    label: 'design-panel.tabs.items.image',
    roles: [DISHES_WIDGET_COMPONENT_IDS.itemImage, UNAVAILABLE_DISHES_COMPONENT_IDS.itemImage],
  },
  {
    label: 'design-panel.tabs.items.background',
    roles: [
      DISHES_WIDGET_COMPONENT_IDS.dishContainerStripBox,
      UNAVAILABLE_DISHES_COMPONENT_IDS.itemContainerStripBox,
    ],
  },
];

export const NAVIGATION_WIDGET_DESIGN_PANEL_TABS: WidgetDesignTabsArray = [
  {
    label: 'design-panel.tabs.navigation.menuPicker',
    roles: [NAVIGATION_TOOLBAR_IDS.menuDropdown],
  },
  {
    label: 'design-panel.tabs.navigation.sectionTabs',
    roles: [NAVIGATION_TOOLBAR_IDS.sectionTabs],
  },
  {
    label: 'design-panel.tabs.navigation.divider',
    roles: [NAVIGATION_TOOLBAR_IDS.navigationDivider],
  },
  {
    label: 'design-panel.tabs.navigation.background',
    roles: [NAVIGATION_TOOLBAR_IDS.box],
  },
];

export const ERROR_MODAL_DESIGN_PANEL_TABS: WidgetDesignTabsArray = [
  {
    label: 'design-panel.tabs.errorModal.title',
    roles: [ERROR_MODAL_COMPONENT_IDS.title],
  },
  {
    label: 'design-panel.tabs.errorModal.description',
    roles: [ERROR_MODAL_COMPONENT_IDS.content],
  },
  {
    label: 'design-panel.tabs.errorModal.button',
    roles: [ERROR_MODAL_COMPONENT_IDS.close],
  },
  {
    label: 'design-panel.tabs.errorModal.background',
    roles: [ERROR_MODAL_COMPONENT_IDS.container],
  },
];
