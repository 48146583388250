import type { Operation, TimeSlot } from 'root/types/businessTypes';
import type { TFunction } from '@wix/yoshi-flow-editor';
import { getTimeString, isToday, isTomorrow } from 'root/components/Header/headerUtils';
import { SchedulingType, DispatchType } from 'root/types/businessTypes';
import type { IBIReporterService } from 'root/services/biReporterService';
import type { DispatchModalForm } from './dispatchModalForm';
import {
  LiveSiteDispatchModalClosedTriggerName,
  LiveSiteClicksInsideDispatchModalButtonType,
} from '@wix/restaurants-bi';
import type { DateTimeFormatOptions } from 'luxon';
import { DateTime } from 'luxon';
import { safeDateTimeConversion } from 'root/utils/dateTimeUtils';

export type TimeSlotOption = {
  value: string;
  label: string;
};

export type DateOption = {
  value: string;
  label: string;
};

export enum ErrorType {
  ADDRESS_NOT_FOUND,
  NO_ADDRESS,
  NO_FULFILLMENTS,
}

export enum SchedulingTypeModalState {
  ASAP_ONLY = 'asap',
  ASAP_AND_FUTURE = 'sameDay',
  PRE_ORDER = 'preorder',
}

export const formatDateOptions: DateTimeFormatOptions = {
  month: 'numeric',
  day: 'numeric',
  weekday: 'long',
};

export const getDispatchTime = (timeSlot: TimeSlot, timezone: string, locale: string) => {
  return {
    from: timeSlot.startTime.setZone(timezone).setLocale(locale),
    until: timeSlot.endTime.setZone(timezone).setLocale(locale),
  };
};

export const convertTimeSlotToDropdownOption = (
  timeSlot: TimeSlot,
  t: TFunction,
  timezone: string,
  locale: string
): TimeSlotOption => {
  const dispatchTime = getDispatchTime(timeSlot, timezone, locale);
  return {
    value: timeSlot.id,
    label: getTimeString(dispatchTime, t),
  };
};

export const convertDateToDropdownOption = (
  date: DateTime,
  timezone: string,
  locale: string,
  t: TFunction
): DateOption => {
  let label;
  if (isToday(date, timezone)) {
    label = t('menu_olo.dispatch.date.today');
  } else if (isTomorrow(date, timezone)) {
    label = t('menu_olo.dispatch.date.tomorrow');
  } else {
    label = safeDateTimeConversion(date).toLocaleDateString(locale, formatDateOptions);
  }

  return { label, value: safeDateTimeConversion(date).toDateString() };
};

export function isValidDate(timezone: string, date?: Date) {
  if (!date) {
    return false;
  }
  const now = DateTime.local().setZone(timezone);
  return DateTime.fromJSDate(date).startOf('day').toMillis() >= now.startOf('day').toMillis();
}

export class BIEventsHandler {
  constructor(
    private form: DispatchModalForm,
    private configuredDispatchTypes: DispatchType[],
    private operation: Operation,
    private isMemberLoggedIn: boolean,
    private storeSchedulingType: SchedulingType,
    private biReporterService?: IBIReporterService
  ) {
    this.onTimeSlotChange = this.onTimeSlotChange.bind(this);
    this.onTimeSlotClick = this.onTimeSlotClick.bind(this);
    this.onDateSlotClick = this.onDateSlotClick.bind(this);
    this.onDateSlotChange = this.onDateSlotChange.bind(this);
    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
    this.onAddressInputClick = this.onAddressInputClick.bind(this);
    this.onAddressNotFound = this.onAddressNotFound.bind(this);
    this.onNoAddress = this.onNoAddress.bind(this);
    this.onNoAvailableFulfillments = this.onNoAvailableFulfillments.bind(this);
    this.onCloseButtonClick = this.onCloseButtonClick.bind(this);
    this.onDatePickerClick = this.onDatePickerClick.bind(this);
    this.onSchedulingTypeChange = this.onSchedulingTypeChange.bind(this);
    this.onDispatchTypeChange = this.onDispatchTypeChange.bind(this);
  }

  onTimeSlotChange() {
    this.biReporterService?.reportOloLiveSiteClicksInsideDispatchModalBiEvent({
      dispatchType: this.form.dispatchType,
      isPreorder: this.form.isPreOrder,
      buttonType: LiveSiteClicksInsideDispatchModalButtonType.TIME_DROPDOWN_OPTION,
      operationId: this.operation.id,
    });
  }

  onTimeSlotClick() {
    this.biReporterService?.reportOloLiveSiteClicksInsideDispatchModalBiEvent({
      dispatchType: this.form.dispatchType,
      isPreorder: this.form.isPreOrder,
      buttonType: LiveSiteClicksInsideDispatchModalButtonType.TIME_DROPDOWN,
      operationId: this.operation.id,
    });
  }

  onDateSlotChange() {
    this.biReporterService?.reportOloLiveSiteClicksInsideDispatchModalBiEvent({
      dispatchType: this.form.dispatchType,
      isPreorder: this.form.isPreOrder,
      buttonType: LiveSiteClicksInsideDispatchModalButtonType.DAY_DROPDOWN_OPTION,
      operationId: this.operation.id,
    });
  }

  onDateSlotClick() {
    this.biReporterService?.reportOloLiveSiteClicksInsideDispatchModalBiEvent({
      dispatchType: this.form.dispatchType,
      isPreorder: this.form.isPreOrder,
      buttonType: LiveSiteClicksInsideDispatchModalButtonType.DAY_DROPDOWN,
      operationId: this.operation.id,
    });
  }

  onSaveButtonClick(selectedTime?: TimeSlot) {
    this.biReporterService?.reportOloLiveSiteDispatchModalClosedBiEvent({
      dispatchType: this.form.dispatchType,
      triggerName: LiveSiteDispatchModalClosedTriggerName.SAVE_BUTTON,
      isAsapDisplayed: this.form.canSubmitOrderForNow,
      isPreorderDisplayed: this.form.isPreOrder,
      schedulingType: this.storeSchedulingType,
      availableDispatchTypes: this.configuredDispatchTypes,
      operationId: this.operation.id,
      isMemberLoggedIn: this.isMemberLoggedIn,
      selectedSchedulingRadioButton: this.form.isSchedulingPickerDisplayed
        ? this.form.schedulingType
        : undefined,
      selectedTime,
      businessDays: this.operation.businessDaysAheadHandlingOptions,
    });
  }

  onAddressInputClick() {
    this.biReporterService?.reportOloLiveSiteClicksInsideDispatchModalBiEvent({
      dispatchType: this.form.dispatchType,
      isPreorder: this.form.isPreOrder,
      buttonType: LiveSiteClicksInsideDispatchModalButtonType.ADDRESS_INPUT,
      operationId: this.operation.id,
    });
  }

  onAddressNotFound() {
    this.biReporterService?.reportOloLiveSiteErrorDisplayedInDispatchModalBiEvent({
      dispatchType: this.form.dispatchType,
      errorType: ErrorType.ADDRESS_NOT_FOUND,
    });
  }

  onNoAddress() {
    this.biReporterService?.reportOloLiveSiteErrorDisplayedInDispatchModalBiEvent({
      dispatchType: this.form.dispatchType,
      errorType: ErrorType.NO_ADDRESS,
      errorMessage: this.form.errorText?.toString(),
    });
  }

  onNoAvailableFulfillments() {
    this.biReporterService?.reportOloLiveSiteErrorDisplayedInDispatchModalBiEvent({
      dispatchType: this.form.dispatchType,
      errorType: ErrorType.NO_FULFILLMENTS,
      errorMessage: this.form.errorText?.toString(),
    });
  }

  onCloseButtonClick(selectedTime?: TimeSlot) {
    this.biReporterService?.reportOloLiveSiteDispatchModalClosedBiEvent({
      dispatchType: this.form.dispatchType,
      triggerName: LiveSiteDispatchModalClosedTriggerName.X_BUTTON,
      isAsapDisplayed: this.form.canSubmitOrderForNow,
      isPreorderDisplayed: this.form.isPreOrder,
      schedulingType: this.storeSchedulingType,
      availableDispatchTypes: this.configuredDispatchTypes,
      operationId: this.operation.id,
      isMemberLoggedIn: this.isMemberLoggedIn,
      selectedSchedulingRadioButton: this.form.isSchedulingPickerDisplayed
        ? this.form.schedulingType
        : undefined,
      selectedTime,
      businessDays: this.operation.businessDaysAheadHandlingOptions,
    });
  }

  onDatePickerClick() {
    this.biReporterService?.reportOloLiveSiteClicksInsideDispatchModalBiEvent({
      dispatchType: this.form.dispatchType,
      isPreorder: this.form.isPreOrder,
      buttonType: LiveSiteClicksInsideDispatchModalButtonType.DAY_DROPDOWN,
      operationId: this.operation.id,
    });
  }

  onSchedulingTypeChange(selectedSchedulingType: SchedulingType) {
    this.biReporterService?.reportOloLiveSiteClicksInsideDispatchModalBiEvent({
      dispatchType: this.form.dispatchType,
      isPreorder: selectedSchedulingType === SchedulingType.PRE_ORDER,
      operationId: this.operation.id,
      buttonType:
        selectedSchedulingType === SchedulingType.ASAP
          ? LiveSiteClicksInsideDispatchModalButtonType.ASAP_RADIO_BUTTON
          : LiveSiteClicksInsideDispatchModalButtonType.SCHEDULE_FOR_LATER_RADIO_BUTTON,
    });
  }

  onDispatchTypeChange(dispatchType: DispatchType) {
    this.biReporterService?.reportOloLiveSiteClicksInsideDispatchModalBiEvent({
      dispatchType,
      isPreorder: this.form.isPreOrder,
      operationId: this.operation.id,
      buttonType:
        dispatchType === DispatchType.PICKUP
          ? LiveSiteClicksInsideDispatchModalButtonType.PICKUP_TAB
          : LiveSiteClicksInsideDispatchModalButtonType.DELIVERY_TAB,
    });
  }
}
